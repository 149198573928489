import { v4 as uuid } from 'uuid';

export const bigBoxes = [
  {
    headline: 'Number of tracks',
    items: [
      { name: 'up to 20', price: 160, id: uuid() },
      { name: 'up to 40', price: 220, id: uuid() },
      { name: 'up to 60', price: 275, id: uuid() },
      { name: 'up to 80', price: 330, id: uuid() },
      { name: 'up to 100', price: 385, id: uuid() },
    ],
    info:
      'Choose the package that fits the amount of audio tracks in your song.',
    number: 9,
    id: uuid(),
  },
  {
    headline: 'Vocal Correction',
    items: [
      { name: 'no correction', price: 0, id: uuid() },
      { name: 'correct 1 track', price: 60, id: uuid() },
      { name: 'correct 2 tracks', price: 95, id: uuid() },
      { name: 'correct 3 tracks', price: 130, id: uuid() },
      { name: 'correct 4 tracks', price: 165, id: uuid() },
    ],
    info:
      'Choose how many vocal tracks you want the pitch and/or timing to be corrected manually.',
    number: 8,
    id: uuid(),
  },
  {
    headline: 'Rhythm Edit',
    items: [
      { name: 'no editing', price: 0, id: uuid() },
      { name: 'edit 1 track', price: 60, id: uuid() },
      { name: 'edit 2 tracks', price: 95, id: uuid() },
      { name: 'edit 3 tracks', price: 130, id: uuid() },
      { name: 'edit 4 tracks', price: 165, id: uuid() },
    ],
    info:
      'Choose how many rythmic tracks you want the timing to be corrected. This will make your song tighter.',
    number: 7,
    id: uuid(),
  },
  {
    headline: 'Additional Mixes',
    items: [
      { name: 'no additonal mixes', price: 0, id: uuid() },
      { name: 'instrumental', price: 35, id: uuid() },
      { name: 'a capella', price: 35, id: uuid() },
      { name: 'backing track', price: 35, id: uuid() },
      { name: 'custom mix', price: 35, id: uuid() },
    ],
    info:
      'Additional mixes can be instrumentals for movies, acapella to create remixes or backing tracks for live performances (instrumental + backing vocals). More combinations are possible with the custom mix option.',
    number: 6,
    id: uuid(),
  },
];

export const smallBoxes = [
  {
    headline: 'Length of your Song',
    items: [
      { name: 'up to 6 minutes', price: 0, id: uuid() },
      { name: 'up to 10 minutes', price: 60, id: uuid() },
    ],
    info: 'Choose the package that fits your track length.',
    number: 5,
    id: uuid(),
  },
  {
    headline: 'Multi-Track Option',
    items: [
      { name: 'without multi-track option', price: 0, id: uuid() },
      { name: 'with multi-track option', price: 40, id: uuid() },
    ],
    info:
      'The multi-track option will provide you all tracks as individually processed files. Of course after you have approved your mix.',
    number: 4,
    id: uuid(),
  },
  {
    headline: 'Mastering',
    items: [
      { name: 'with mastering', price: 40, id: uuid() },
      { name: 'without mastering', price: 0, id: uuid() },
    ],
    info:
      'We recommend to complete the mix with our own mastering. However, you can choose not to master your song in Ekout Studio.',
    number: 3,
    id: uuid(),
  },
  {
    headline: 'Revisions',
    items: [
      { name: 'up to 2 revisions', price: 0, id: uuid() },
      { name: 'unlimited revisions', price: 75, id: uuid() },
    ],
    info:
      'Select this option if you want unlimited revisions within 4 weeks after the first mix has been delivered.',
    number: 2,
    id: uuid(),
  },
  {
    headline: 'Express Service',
    items: [
      { name: 'no', price: 0, id: uuid() },
      { name: 'yes', price: 175, id: uuid() },
    ],
    info:
      'With the express service we will deliver your first mix/master within 3 business days after receiving your material. Instead of the usual 7 - 10 business days.',
    number: 1,
    id: uuid(),
  },
];
