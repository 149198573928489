import { v4 as uuid } from 'uuid';

export const introText = [
  {
    paragraph:
      'Do you want your music creations to stand out on the radio? Sound powerful with the perfect amount of punch, so you can compete with other releases?',
    id: uuid(),
  },
  {
    paragraph:
      '<strong>Welcome to Ekout Studio in Berlin!</strong> My hybrid setup combines analog and digital processing in an acoustically treated environment. I’m Vassili and I will elevate your audio productions to a professional level, with the perfect sound for any style!',
    id: uuid(),
  },
];

export const hardware = [
  { name: 'RME Fireface UFX+', type: 'Audio Interface', id: uuid() },
  { name: 'EL8X-S Distressor (2x)', type: 'Analog Compressor', id: uuid() },
  { name: 'SSL Fusion', type: 'Analog Master Processor', id: uuid() },
  { name: 'Neumann KH 310 A', type: 'Midfield Monitors', id: uuid() },
  { name: 'Neumann KH 750 DSP', type: 'Subwoofer', id: uuid() },
  { name: 'Neumann MA 1', type: 'Speaker Calibration', id: uuid() },
  { name: 'Austrian Audio OC818', type: 'Condenser Mic', id: uuid() },
  { name: 'Shure Beta 58A', type: 'Dynamic Mic', id: uuid() },
  { name: 'SPL Madison', type: '16 Channel AD/DA-Converter', id: uuid() },
  { name: 'Sequential Prophet-5', type: 'Analog Polysynth', id: uuid() },
  { name: 'Sequential Pro 3', type: 'Analog Paraphonic Synth', id: uuid() },
  { name: 'Oberheim OB-6', type: 'Analog Polysynth', id: uuid() },
  { name: 'ASM Hydrasynth', type: 'Digital Polysynth', id: uuid() },
  { name: 'Moog Subharmonicon', type: 'Semi-Modular Synth', id: uuid() },
  { name: 'Kawai CS-11', type: 'Premium Digital Piano', id: uuid() },
  { name: 'Yamaha DX7', type: 'FM Synthesizer', id: uuid() },
  { name: 'Electron Rytm', type: 'Analog Drum Machine', id: uuid() },
  { name: 'Jomox MBase 11', type: 'Analog Bass Drum', id: uuid() },
  { name: 'Microcosm', type: 'Granular Effects Processor', id: uuid() },
  { name: 'Presonus Faderport 16', type: 'DAW Controller', id: uuid() },
  { name: 'Ableton Push 2', type: 'DAW Controller', id: uuid() },
];

export const software = [
  { name: 'Ableton Live', type: 'DAW', id: uuid() },
  { name: 'Soundtoys 5', type: 'Collection', id: uuid() },
  { name: 'Lexicon PCM Total Bundle', type: 'Collection', id: uuid() },
  { name: 'Arturia V', type: 'Collection', id: uuid() },
  { name: 'FabFilter Pro-Q3', type: 'Dynamic EQ', id: uuid() },
  { name: 'FabFilter Pro-R', type: 'Reverb', id: uuid() },
  { name: 'FabFilter Pro-L', type: 'Limiter', id: uuid() },
  { name: 'FabFilter Pro-MB', type: 'Multiband Compressor', id: uuid() },
  { name: 'FabFilter Timeless 3', type: 'Delay', id: uuid() },
  { name: 'Empirical Labs Arousor', type: 'Compressor', id: uuid() },
  { name: 'Wavesfactory Spectre', type: 'Multi-Band Enhancer', id: uuid() },
  { name: 'Wavesfactory Trackspacer', type: 'Dynamics Processor', id: uuid() },
  { name: 'Softube Tape', type: 'Tape Saturator', id: uuid() },
  { name: 'Audiority Space Station UM282', type: 'Echo/Reverb', id: uuid() },
  { name: 'Denise Perfect Room', type: 'Reverb', id: uuid() },
  { name: 'Denise Perfect Plate XL', type: 'Reverb', id: uuid() },
  { name: 'Waves Tune Real-Time', type: 'Pitch Correction', id: uuid() },
  { name: 'Waves Aphex Vintage Exciter', type: 'Vocal Enhancer', id: uuid() },
  { name: 'Waves Vocal Rider', type: 'Dynamics Processor', id: uuid() },
  { name: 'Waves CLA Epic', type: 'Delay/Reverb', id: uuid() },
  { name: 'Waves Sibilance', type: 'De-Esser', id: uuid() },
  { name: 'Waves CLA-76', type: 'Compressor', id: uuid() },
  { name: 'Waves CLA-2A', type: 'Compressor', id: uuid() },
  { name: 'Waves API-2500', type: 'Compressor', id: uuid() },
];
