import { v4 as uuid } from 'uuid';

export const mixingAndMastering = [
  {
    cover:
      'https://twshosting.de/wordpress/ekoutstudio/pics/elmagista-small.jpg',
    before: 'https://twshosting.de/wordpress/ekoutstudio/elmagista-demo.mp3',
    after:
      'https://twshosting.de/wordpress/ekoutstudio/elmagista-master.mp3',
    artist: 'Van Keppel',
    title: 'El Magista',
    id: uuid(),
  },
  {
    cover:
      'https://twshosting.de/wordpress/ekoutstudio/pics/inthemoonlight-small.jpg',
    before: 'https://twshosting.de/wordpress/ekoutstudio/inthemoonlight-demo.mp3',
    after:
      'https://twshosting.de/wordpress/ekoutstudio/inthemoonlight-master.mp3',
    artist: 'Pelikaan',
    title: 'In The Moonlight (Manu Ela Remix)',
    id: uuid(),
  },
  {
    cover:
      'https://twshosting.de/wordpress/ekoutstudio/pics/turandot-small.jpg',
    before: 'https://twshosting.de/wordpress/ekoutstudio/turandot-demo.mp3',
    after:
      'https://twshosting.de/wordpress/ekoutstudio/turandot-master.mp3',
    artist: 'canaia',
    title: 'Turandot',
    id: uuid(),
  },  
];

export const productionAndComposition = [
  {
    cover:
      'https://twshosting.de/wordpress/ekoutstudio/pics/pineapple-run.jpg',
    before: 'https://twshosting.de/wordpress/ekoutstudio/pineapple-run.mp3',
    artist: 'Pineapple Run',
    title: 'Mobile Game',
    id: uuid(),
  },
];
