import { v4 as uuid } from 'uuid';

export const faqs = [
  {
    question: 'HOW TO PREPARE MY FILES FOR MIXING AND MASTERING?',
    details: [
      'Please provide all audio files in WAV or AIFF format.',
      'Please name your tracks properly and remove unnecessary track suffixes (»Bassdrum« is better than »Audio-14-new2« etc.).',
      'To avoid synchronization issues, please export all audio tracks from the exact same starting point.',
      'Keep the bit depth at maximum (preferably 24 bit or higher).',
      'Unprocessed (dry) tracks are preferred. In addition you can provide a second set of files, including your preferred processing. Add a comment that there is an alternate processed/unprocessed track.',
      'If you want me to do just a mastering, please deliver 1 stereo file at the best quality available. Do not apply any limiting and leave about 3-6db of headroom.',
    ],
    id: uuid(),
  },
  {
    question: 'IS THERE ANYTHING ELSE I CAN INCLUDE?',
    details: [
      'Please include tempo information.',
      'Please include the key of the song.',
      'Please include a rough mix (premix).',
      'Feel free to provide reference tracks.',
    ],
    id: uuid(),
  },
  {
    question: 'DATA EXCHANGE - HOW CAN I SEND YOU MY FILES?',
    details: [
      'I recommend you to send me your files via WeTransfer. Otherwise feel free to use any other file transfer service like Dropbox, Googledrive, etc..',
      'Alternatively, you can of course send me a link to download your files from your server.',
      'Please do not send your files via e-mail!',
    ],
    id: uuid(),
  },
  {
    question: 'HOW LONG DOES IT TAKE TO INCORPORATE MY FEEDBACK?',
    details: [
      'I will deliver the first revision of your mixed and mastered file within 7-10 business days, after receiving your material. However, there is an express service you can purchase, which will decrease the time to 3 business days - check my Prices!',
      '2 revisions are always included. However, you can purchase the option to get unlimited revisions within 4 weeks after the first file has been delivered - check my Prices!',
      'I prefer you send me your feedback via e-mail. However, you can reach me on a video chat or a simple call, as well. In order to do that, I would ask you to use the form in my contact page to set up an appointment in advance.',
    ],
    id: uuid(),
  },
  {
    question: 'HOW CAN I PAY AND ARE THERE DISCOUNTS?',
    details: [
      'I accept PayPal and direct bank transfer.',
      "Please be aware, all prices don't include local taxes.",
      'There are reasons for discounts, for instance when you want me to work on a whole album. Let me know if you think that you have a reason to receive a discount and I will take it into consideration.',
    ],
    id: uuid(),
  },
];
