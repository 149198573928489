import { v4 as uuid } from 'uuid';

import keyboard from '../assets/svgs/services-keyboard.svg';
import mixer from '../assets/svgs/services-mixer.svg';
import producer from '../assets/svgs/services-producer.svg';

export const services = [
  {
    headline: 'Engineering',
    icon: mixer,
    elements: [
      {
        headline: 'Mixing',
        list: [
          'EQing',
          'Dynamics Control',
          'Depth (Reverb/Delay)',
          'Panning',
          'Leveling',
          'Creative Effects',
          'Vocal Editing (Tuning)',
          'Rhythm Editing (Timing)',
        ],
        id: uuid(),
      },
      {
        headline: 'Mastering',
        list: [
          'Final EQ Balance',
          'Final Stereo Width',
          'Multiband Compression',
          'Genre Specific Loudness',
        ],
        id: uuid(),
      },
    ],
    id: uuid(),
  },
  {
    headline: 'Producing',
    icon: producer,
    elements: [
      {
        headline: 'Orchestration',
        paragraph:
          'You are a singer/songwriter and done composing your song? I can produce your composition by applying a combination of electronic instruments, such as synthesizers and drum machines, with personalized sounds fitting to your style.',
        id: uuid(),
      },
      {
        headline: 'Beats',
        paragraph:
          'I will produce your own individual instrumental, jingle or sound-logo.',
        id: uuid(),
      },
      {
        headline: 'Remixing',
        paragraph:
          'I will produce an entire track, based on your composition or vocals.',
        id: uuid(),
      },
    ],
    id: uuid(),
  },
  {
    headline: 'Composing',
    icon: keyboard,
    elements: [
      {
        headline: 'Film/Theatre',
        paragraph:
          'You want a synth-based score for your film/play, à la Stranger Things, Blade Runner or any John Carpenter movie? I will work with you to create the perfect atmosphere for your movie.',
        id: uuid(),
      },
      {
        headline: 'Video Games',
        paragraph:
          'Growing up playing video games, I’ve always loved the musical experience in games like Zelda, Street Fighter and Super Mario. So if you’re looking for a synth-based score for your game, we should get in touch.',
        id: uuid(),
      },
    ],
    id: uuid(),
  },
];
